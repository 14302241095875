import { Flex } from "@chakra-ui/react";
import styles from "./Home.module.scss";
import Body from "../../component/Body/Body";
export default function Home() {
  return (
    <Flex className={styles.container}>
      <Body />
    </Flex>
  );
}
