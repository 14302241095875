import { Flex, Image, Text } from "@chakra-ui/react";
import bgImage from "../../assets/image/sky.png";
import pillsImage from "../../assets/image/pills-in-box.png";
import tgImage from "../../assets/image/tg.png";

import styles from "./Body.module.scss";
import { menuLinks } from "src/common/pageConfig";
import { useIsMobile } from "src/hook/useIsMobile";

export default function Header() {
  const isMobile = useIsMobile();
  const botUrl = process.env.REACT_APP_BOT_URL!;
  return (
    <Flex className={styles.container}>
      <Flex flexDir={"column"} alignItems={"center"} gap={6}>
        <Image src={pillsImage} alt="logo" width="120px" />
        <Text fontFamily={"Rubik Doodle Shadow"} fontWeight={400} fontSize={{ md: "32px", base: "24px" }}>
          Pump Safely. Trade Smart
        </Text>
        <Text fontWeight={400} fontSize={"16px"}>
          Connect your telegram to join the waitlist
        </Text>

        <Flex alignItems={"center"}>
          <Image src={tgImage} alt="logo" width={{ md: "50px", base: "32px" }} height={{ md: "50px", base: "32px" }} />
          <Text
            fontWeight={400}
            fontSize={"16px"}
            bg={"white"}
            px={6}
            height={{ md: "50px", base: "32px" }}
            lineHeight={{ md: "50px", base: "32px" }}
            borderRightRadius={"60px"}
            cursor={"pointer"}
            userSelect={"none"}
            onClick={() => window.open(botUrl, "_blank")}
          >
            Connect Telegram
          </Text>
        </Flex>
      </Flex>
      {/* <Flex fontWeight={400} fontSize={"16px"} position={"absolute"} bottom={{ md: "60px", base: "20px" }} gap={"60px"}>
        <Text>Privacy Policy</Text>
        <Text>Terms & Condition</Text>
      </Flex> */}
      {/* <Image src={bgImage} alt="logo" className={styles.bgImage} /> */}
    </Flex>
  );
}
