import { Flex } from "@chakra-ui/react";
import styles from "./Layout.module.scss";
import Header from "../Header/Header";

export default function Layout(props: { children: React.ReactNode }) {
  return (
    <Flex className={styles.container}>
      <Flex width="calc(100%)" minHeight="100vh" flexDirection="column">
        <Header />
        <Flex width="100%" height="100%">
          {props.children}
        </Flex>
      </Flex>
    </Flex>
  );
}
