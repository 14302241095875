import Home from "src/page/Home/Home";
import Layout from "./component/Layout/Layout";
import NotFound from "./page/NotFound";

const routes = [];

routes.push(
  {
    path: "/",
    element: <Layout>{<Home />}</Layout>,
  },
  {
    path: "*",
    element: <Layout>{<NotFound />}</Layout>,
  }
);

export { routes };
